<template>
<!--  质控科 -->
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">科室绩效数据填报</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item label="时间：">
              <el-date-picker
                  v-model="startTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changestartTime"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="科室：">
              <el-select v-model="typeValue" placeholder="请选择科室" clearable filterable  @change="typeValueChange(typeValue)" class="xiangType">
                <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
              prop="basePeriodTime"
              label="时间"
              align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="科室" align="center">
          </el-table-column>
          <el-table-column prop="otherSpecial" label="体检专项(体检科)" align="center">
          </el-table-column>
          <el-table-column prop="nightDutyAllowance" label="夜班费" align="center">
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <div style="color: #81D3F8" v-if="scope.row.status6=='0'">待填写</div>
              <div style="color: #FACD91" v-if="scope.row.status6=='1'">待审核</div>
              <div style="color: #039B13" v-if="scope.row.status6=='2'">审核通过</div>
              <div style="color: #D9001B" v-if="scope.row.status6=='3'">审核失败</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="220">
            <template style="display: flex" slot-scope="scope">
              <!--待填写显示填报-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status6=='0'"
                  @click="fillClick(scope.row)"
              >填报</el-button>
              <!--待审核显示查看、审核通过显示查看-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status6=='1'"
                  @click="lookClick(scope.row)"
              >编辑</el-button>
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status6=='2'"
                  @click="lookClick(scope.row)"
              >查看</el-button>
              <!--审核失败显示重新填写、查看驳回原因-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.status6=='3'"
                  @click="refillClick(scope.row)"
              >重新填写</el-button>
              <el-button
                  size="mini"
                  type="warning"
                  v-if="scope.row.status6=='3'"
                  @click="reasonClick(scope.row)"
              >查看驳回原因</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog
        :title="fillTitle"
        :visible.sync="FillingVisible"
        width="600px"
    >
      <el-form
          :model="Performance"
          ref="PerformanceRef"
          :rules="PerformanceRules"
          :inline="true"
          label-width="120px"
      >
        <el-form-item prop="nightVal" class="hosform" v-if="nighShow">
          <div class="tabBig">
            <div class="boxOne">
              夜班费
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input-number :disabled="disabled" v-model="Performance.nightVal" :precision="2"></el-input-number>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="otherSpecial" class="hosform">
          <div class="tabBig">
            <div class="boxOne">
              体检专项(体检科)
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              <el-input-number :disabled="disabled" v-model="Performance.otherSpecial" :precision="2"></el-input-number>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="btnShow">
          <div class="btnSize">
            <el-button type="primary" @click="onsubmit"
            >提交数据</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 驳回 -->
    <el-dialog
        :title="rejectTitle"
        :visible.sync="rejectVisible"
        width="30%"
    >
      <el-checkbox-group disabled v-model="checkedCities" @change="handleCheckedCitiesChange" class="checRev">
        <el-checkbox v-for="reje in rejectOption" :label="reje" :key="reje">{{reje}}</el-checkbox>
      </el-checkbox-group>
      <div class="btnSize">
        <el-button type="primary" @click="checSubmit">关闭</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      deptId:window.sessionStorage.getItem("deptId"),
      checkedCities: [],
      rejectOption:  [],
      rejectTitle:'',
      rejectVisible:false,
      // 只选择自己想选的年份和月份
      creactMonthArr:['2022-05','2022-08'],
      pickerOptionsEndequivalent: {
        disabledDate: (time) => {
          // console.log(time)
          const year = time.getFullYear()
          let month = time.getMonth() + 1
          if (month < 10) month = '0' + month
          const ym = year + '-' + month
          return !this.creactMonthArr.includes(ym)
        },
      },
      depoptions:[],
      typeValue:"",
      startTime:'',
      disabled:false,
      btnShow:true,
      fillTitle:'填报',
      // 填报
      Performance: {
        nightVal:'',
        otherSpecial:""
      },
      // 绩效分配结构规则
      PerformanceRules: {
        otherSpecial: [
          {
            required: true,
            message: "请输入体检专项(体检科)",
            trigger: "blur",
          },
        ],
        nightVal: [
          {
            required: true,
            message: "请输入夜班费",
            trigger: "blur",
          },
        ],
      },
      // 填报弹出框
      FillingVisible: false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      bascId:"",
      basbasePeriodTime:"",
      basStatus:"",
      nighShow:false
    };
  },
  created() {
    this.getDateTime()
    // 科室数据查询
    this.findDepartment()
  },
  methods: {
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.getDepartmentNotPage();
    },
    typeValueChange(val){
      this.typeValue=val
      this.getDepartmentNotPage();
    },
    async findDepartment(){
      let data = {
        hospitalId:window.sessionStorage.getItem("hospitalId")
      };
      let { data: res } = await this.$axios.findDepartment(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.depoptions = res.data;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    checSubmit(){
      this.rejectVisible=false
    },
    handleCheckedCitiesChange(value) {
      if(value){
        this.checkedCities=value
      }
    },
    changestartTime(val){
        this.startTime=val
        this.getDepartmentNotPage();
    },
    fillClick(row) {
      this.bascId=row.id
      this.basbasePeriodTime=row.basePeriodTime
      this.Performance.nightVal=row.nightDutyAllowance//夜班费
      this.Performance.otherSpecial=row.otherSpecial //体检专项(体检科)
      this.fillTitle='填报'+row.name+row.basePeriodTime+'绩效填报'
      this.basStatus=row.status6
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false

      if(window.sessionStorage.getItem("departmentId")==row.departmentId){
        this.nighShow=true
      }else{
        this.nighShow=false
      }
    },
    lookClick(row){
      this.bascId=row.id
      this.basbasePeriodTime=row.basePeriodTime
      this.Performance.nightVal=row.nightDutyAllowance//夜班费
      this.Performance.otherSpecial=row.otherSpecial //体检专项(体检科)
      this.fillTitle='查看'+row.name+row.basePeriodTime+'绩效填报'
      this.basStatus=row.status6
      this.FillingVisible=true

      // 1 待审核 2 审核通过
      if(row.status6==2){
        this.disabled=true
        this.btnShow=false
      }if(row.status6==1){
        this.disabled=false
        this.btnShow=true
      }

      if(window.sessionStorage.getItem("departmentId")==row.departmentId){
        this.nighShow=true
      }else{
        this.nighShow=false
      }
    },
    refillClick(row){
      this.bascId=row.id
      this.basbasePeriodTime=row.basePeriodTime
      this.Performance.nightVal=row.nightDutyAllowance//夜班费
      this.Performance.perexpVal=row.painfulDelivery//阵痛分娩奖励
      this.Performance.twoCanVal=row.cancerScreen//两癌筛查
      this.Performance.medifuVal=row.medicalInsuranceFund//医保基金
      this.Performance.bonsuVal=row.bonusSubsidy//奖金补助
      this.Performance.perexpVal=row.personnelExpenditure//人员列支
      this.Performance.exlabexp=row.outCheckExpend//外院化验支出
      this.Performance.refexlabt=row.outCheckIncome//外院化验收入
      this.basStatus=row.status6

      if(window.sessionStorage.getItem("departmentId")==row.departmentId){
        this.nighShow=true
      }else{
        this.nighShow=false
      }
      this.fillTitle=row.name+row.basePeriodTime+'绩效填报'
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
    },
    reasonClick(row){
      this.rejectVisible=true
      this.rejectTitle='驳回原因'
      if(row.name=='妇产科'){
        this.rejectOption=['夜班费', '质控奖罚', '两癌筛查','医保基金','奖金补助','人员列支','体检专项(体检科)','体检专项','外院化验收入','外院化验支出','阵痛分娩奖励', '其他']
      }else{
        this.rejectOption=['夜班费', '质控奖罚', '两癌筛查','医保基金','奖金补助','人员列支','体检专项(体检科)','体检专项','外院化验收入','外院化验支出', '其他']
      }
      this.checkedCities=row.reasonForRejection.split(',')
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   获取绩效填报列表
    async getDepartmentNotPage() {
      let data = {
        basePeriodTime:this.startTime,//时间
        departmentId:this.typeValue,
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        pageType:"FILL",//填报列表
      };
      let { data: res } = await this.$axios.findCurrentYearAndMonthInformation(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onsubmit() {
      this.$refs.PerformanceRef.validate(async(valid) => {
        if (valid) {
          let data
          if(this.nighShow){
            data = _qs.stringify({
              id: this.bascId,//主键
              basePeriodTime:this.basbasePeriodTime,//年份
              // departmentId:window.sessionStorage.getItem("departmentId"),//科室id
              status6:this.basStatus,
              nightDutyAllowance:this.Performance.nightVal,//夜班费
              otherSpecial:this.Performance.otherSpecial,//体检专项(体检科)
            });
          }else{
            data = _qs.stringify({
              id: this.bascId,//主键
              basePeriodTime:this.basbasePeriodTime,//年份
              // departmentId:window.sessionStorage.getItem("departmentId"),//科室id
              status6:this.basStatus,
              otherSpecial:this.Performance.otherSpecial,//体检专项(体检科)
            });
          }

          let { data: res } = await this.$axios.perdatRep(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getDepartmentNotPage()
            this.FillingVisible=false
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 500px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
 /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.twoForm {
  float: right;
  display: flex;
}

/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
width: 25px;
height: 25px;
color: white;
line-height: 25px;
text-align: center;
margin: 0 auto;
}

.noStatus {
background: #ccc;
}
.depTitle {
display: flex;
width: 100%;
margin: 0 auto;
margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
width: 100px;
}
.el-pagination {
margin-top: 20px;
}

.btnSize button{
  width: 120px;
  margin-top: 20px;
}

/deep/ .el-input-number__decrease,/deep/ .el-input-number__increase{
  display: none;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}
</style>
